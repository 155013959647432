import { handleActions } from "redux-actions";
import {
  BEGIN_LOGIN_USER,
  FINISH_LOGIN,
  LOGIN_FAILED
} from "../components/Home/HomeContainer";

const defaultState = {
  isLoggingIn: false,
  idToken: "",
  refreshToken: "",
  userId: "",
  loginFailed: false,
  userType: "",
  emailAddress: ""
};

const reducer = handleActions(
  {
    [BEGIN_LOGIN_USER]: (state: any, action: any) => ({
      ...state,
      isLoggingIn: true
    }),
    [FINISH_LOGIN]: (state: any, action: any) => ({
      ...state,
      idToken: action.payload.id_token,
      refreshToken: action.payload.refresh_token,
      userId: action.payload.user_id,
      userType: action.payload.user,
      emailAddress: action.payload.email,
      isLoggingIn: false
    }),
    [LOGIN_FAILED]: (state: any, action: any) => ({
      ...state,
      loginFailed: true
    })
  },
  defaultState
);

export default reducer;
