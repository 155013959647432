import React from "react";
import DisclaimerStyles from "./DisclaimerFooter.module.css";

interface DisclaimerFooterProps {
  className?: string;
}

const Footer: React.FC<DisclaimerFooterProps> = ({ className }) => {
  return (
    <div className={`${DisclaimerStyles.disclaimer} ${className}`}>
      <strong>DISCLAIMER:</strong> The ANNE system is an experimental sensor for
      investigational use only. It is not to be used for clinical decision
      making. All clinical decisions should be based on standard of care
      systems.
    </div>
  );
};

export default Footer;
