import { handleActions, combineActions } from "redux-actions";
import {
  BEGIN_FETCH_ACCOUNT_INFORMATION,
  FINISH_FETCH_ACCOUNT_INFORMATION
} from "../sagas/account";

const defaultState = {
  siteId: "",
  loading: false,
  userLevel: "",
  username: ""
};

const reducer = handleActions(
  {
    BEGIN_FETCH_ACCOUNT_INFORMATION: (state: any, action: any) => ({
      ...state,
      loading: true
    }),
    FINISH_FETCH_ACCOUNT_INFORMATION: (state: any, action: any) => ({
      loading: false,
      siteId: action.payload.SiteID,
      userLevel: action.payload.UserLevel,
      username: action.payload.Username
    })
  },
  defaultState
);

export default reducer;
