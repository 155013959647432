import React from "react";
import PageTitleStyles from "./PageTitle.module.css";
interface PageTitleProps {
  title: string;
  className?: string;
}

const PageTitle: React.FC<PageTitleProps> = ({ title, className }) => {
  return (
    <React.Fragment>
      <h2 className={`${PageTitleStyles.title} ${className}`}>{title}</h2>
    </React.Fragment>
  );
};

export default PageTitle;
