import React from "react";
import { Input, Button, Form, Layout } from "antd";
import HelpStyles from "./Help.module.css";
import PageTitle from "../PageTitle/PageTitle";
import DisclaimerFooter from "../DisclaimerFooter/DisclamerFooter";

const { TextArea } = Input;
const { Content } = Layout;

interface HelpProps {
  form: {
    getFieldDecorator: Function;
    validateFields: Function;
    resetFields: Function;
  };
  fetchSubmitHelpInformation: Function;
  isHelpSubmitting: boolean;
  helpSubmitSucceeded: boolean;
}

const Help: React.FC<HelpProps> = ({
  form: { getFieldDecorator, validateFields, resetFields },
  fetchSubmitHelpInformation,
  isHelpSubmitting,
  helpSubmitSucceeded
}) => {
  const handleSubmit = (e: any) => {
    e.preventDefault();
    validateFields((err: Object, values: Object) => {
      if (!err) {
        fetchSubmitHelpInformation(values);
      }
    });
  };

  helpSubmitSucceeded && resetFields();

  return (
    <React.Fragment>
      <Layout>
        <DisclaimerFooter className={HelpStyles.disclaimer} />
        <div className={HelpStyles.container}>
          <PageTitle title={"Help Center"} />
          <Content className={HelpStyles.content}>
            <div className={HelpStyles.contentBox}>
              <h2 className={HelpStyles.helpHeader}>
                We are here to help! Please contact us if you have any problems.
              </h2>
              <div className={HelpStyles.contactContainer}>
                <div className={HelpStyles.innerContainer}>
                  <h4>Address</h4>
                  <span className={HelpStyles.contactInformation}>
                    1900 Greenwood St. Unit 8 Evanston, IL 60201 United States
                  </span>
                </div>
                <div className={HelpStyles.innerContainer}>
                  <h4>Telephone</h4>
                  <span className={HelpStyles.contactInformation}>
                    +1 (224) 999-7068
                  </span>
                </div>
                <div className={HelpStyles.innerContainer}>
                  <h4>Email</h4>
                  <a className={HelpStyles.contactInformation}>
                    support@sibelhealth.com
                  </a>
                </div>
                <div className={HelpStyles.innerContainer}>
                  <h4>Website</h4>
                  <a
                    className={HelpStyles.contactInformation}
                    href="https://www.sibelhealth.com/"
                    target="_blank"
                  >
                    http://sibelhealth.com
                  </a>
                </div>
              </div>
              <Form onSubmit={handleSubmit} className={"helpForm"}>
                <div className={HelpStyles.formContainer}>
                  <Form.Item className={HelpStyles.fieldItem}>
                    {getFieldDecorator("name", {
                      rules: [
                        { required: true, message: "Please input a name" }
                      ]
                    })(<Input name="name" placeholder="Name*" />)}
                  </Form.Item>
                  <Form.Item className={HelpStyles.fieldItem}>
                    {getFieldDecorator("email", {
                      rules: [
                        { required: true, message: "Please input an email" }
                      ]
                    })(
                      <Input name="email" placeholder="Email*" type="email" />
                    )}
                  </Form.Item>
                </div>
                <Form.Item>
                  {getFieldDecorator("subject", {
                    rules: [
                      { required: true, message: "Please input a subject" }
                    ]
                  })(
                    <Input
                      type="subject"
                      name="subject"
                      placeholder="Subject*"
                      className={HelpStyles.subject}
                    />
                  )}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator("message", {
                    rules: [
                      { required: true, message: "Please input a message" }
                    ]
                  })(
                    <TextArea
                      name="message"
                      placeholder="Message*"
                      style={{ minHeight: 200 }}
                    />
                  )}
                </Form.Item>

                <Button
                  type="primary"
                  htmlType="submit"
                  className={HelpStyles.submitButton}
                  loading={isHelpSubmitting}
                >
                  Send Message
                </Button>
              </Form>
            </div>
          </Content>
        </div>
      </Layout>
    </React.Fragment>
  );
};

export default Form.create({ name: "HelpForm" })(Help);
