import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Layout, Menu, Icon } from "antd";
import LayoutStyles from "./Layout.module.css";
import ExtendedLogo from "../../assets/ExtendedLogo.svg";
import { getSessionStorage } from "../../sagas/utils";
const { Sider } = Layout;

interface MainLayoutProps {
  pathname: string;
  userType: string;
}

const MainLayout: React.FC<MainLayoutProps> = ({ pathname, userType }) => {
  const user = !!userType ? userType : getSessionStorage("userType");
  return (
    <React.Fragment>
      {pathname === "/" ||
      pathname === "/register" ||
      pathname === "/confirmation" ? null : (
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          className={LayoutStyles.layoutContainer}
        >
          <img
            src={ExtendedLogo}
            className={LayoutStyles.extendedLogoContainer}
          />
          <Menu
            theme="light"
            mode="inline"
            defaultSelectedKeys={["/dashboard"]}
            selectedKeys={[pathname]}
            className={LayoutStyles.menuContainer}
          >
            <Menu.Item key="/dashboard">
              <Link to={"/dashboard"} />
              <Icon type="layout" />
              <span className="nav-text">Dashboard</span>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="/sessions">
              <Link to={"/sessions"} />
              <Icon type="cloud-download" />
              <span className="nav-text">Sessions</span>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="/help">
              <Link to={"/help"} />
              <Icon type="question-circle" />
              <span className="nav-text">Help</span>
            </Menu.Item>
            <Menu.Item key="4">
              <Link to={"/logout"} />
              <Icon type="logout" />
              <span className="nav-text">Log Out</span>
            </Menu.Item>
            <Menu.Divider />
            {user === "privileged_user" && (
              <Menu.Item key="/admin">
                <Link to={"/admin"} />
                <Icon type="usergroup-add" />
                <span className="nav-text">Admin</span>
              </Menu.Item>
            )}
          </Menu>
        </Sider>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    pathname: state.router.location.pathname,
    userType: state.login.userType
  };
};

export default connect(mapStateToProps)(MainLayout);
