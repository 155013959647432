import { handleActions } from "redux-actions";
import {
  BEGIN_SUBMIT_HELP_INFORMATION,
  FINISH_SUBMIT_HELP_INFORMATION,
  ERROR_SUBMIT_HELP_INFORMATION
} from "../sagas/help";

const defaultState = {
  submitting: false,
  succeeded: false
};

const reducer = handleActions(
  {
    BEGIN_SUBMIT_HELP_INFORMATION: (state: any, action: any) => ({
      submitting: true
    }),
    FINISH_SUBMIT_HELP_INFORMATION: (state: any, action: any) => ({
      submitting: false,
      succeeded: true
    }),
    ERROR_SUBMIT_HELP_INFORMATION: (state: any, action: any) => ({
      submitting: false,
      succeeded: false
    })
  },
  defaultState
);

export default reducer;
