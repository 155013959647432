import { take, put, call } from "redux-saga/effects";
import { notification } from "antd";
import * as actions from "../components/Account/AccountContainer";
import { createAction } from "redux-actions";
import sibelAPI from "../apis";
import { getSessionStorage } from "./utils";

export const BEGIN_FETCH_RESET_PASSWORD = "BEGIN_FETCH_RESET_PASSWORD";
export const FINISH_FETCH_RESET_PASSWORD = "FINISH_FETCH_RESET_PASSWORD";

export const beginFetchResetPassword = createAction(
  "BEGIN_FETCH_RESET_PASSWORD"
);
export const finishFetchResetPassword = createAction(
  "FINISH_FETCH_RESET_PASSWORD"
);

export function* watchResetPassword() {
  while (true) {
    const { payload } = yield take(BEGIN_FETCH_RESET_PASSWORD);
    yield call(fetchAccountInformation, payload);
  }
}

export function* fetchAccountInformation(payload: any) {
  try {
    const response = yield call(
      sibelAPI,
      "clinicalInvestigatorResetPassword",
      payload
    );
    notification.open({
      type: "success",
      message: "Password reset"
    });
    yield put(finishFetchResetPassword(response.data));
  } catch (error) {
    notification.open({
      type: "error",
      message: error.message
    });
  }
}
