import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ResetPassword from "./ResetPassword";
import { beginFetchResetPassword } from "../../sagas/resetPassword";
import { getSessionStorage } from "../../sagas/utils";

function mapState(state: any) {
  return {
    email: state.login.emailAddress || getSessionStorage("email"),
    userType: state.login.userType || getSessionStorage("userType")
  };
}

function mapDispatch(dispatch: any) {
  return {
    resetPassword: bindActionCreators(beginFetchResetPassword, dispatch)
  };
}

export default connect(
  mapState,
  mapDispatch
)(ResetPassword);
