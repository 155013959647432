import React, { useEffect } from "react";
import { Layout, Button, Form, Input, Icon } from "antd";
import ResetPasswordStyles from "./ResetPassword.module.css";
import PageTitle from "../PageTitle/PageTitle";
import DisclaimerFooter from "../DisclaimerFooter/DisclamerFooter";
import { getSessionStorage } from "../../sagas/utils";
import Logo from "../../assets/Logo.svg";

const { Content } = Layout;

interface ReseetAccountProps {
  form: {
    getFieldDecorator: Function;
    validateFields: Function;
    resetFields: Function;
  };
  resetPassword: Function;
  email: string;
  userType: string;
}

const ReseetAccount: React.FC<ReseetAccountProps> = ({
  form: { getFieldDecorator, validateFields, resetFields },
  email,
  resetPassword
}) => {
  const handleSubmit = (e: any) => {
    e.preventDefault();
    validateFields(
      (
        err: Object,
        {
          oldPassword,
          newPassword,
          verifyPassword
        }: { oldPassword: string; newPassword: string; verifyPassword: string }
      ) => {
        if (!err) {
          resetPassword({
            email,
            oldPassword,
            newPassword,
            verifyPassword
          });
        }
      }
    );
  };

  return (
    <React.Fragment>
      <Layout>
        <DisclaimerFooter className={ResetPasswordStyles.disclaimer} />
        <div className={ResetPasswordStyles.container}>
          <PageTitle title={"Password Reset"} />
          <Content className={ResetPasswordStyles.passwordContent}>
            <img src={Logo} className={ResetPasswordStyles.logo} />
            <Form onSubmit={handleSubmit} className={"helpForm"}>
              <div className={ResetPasswordStyles.formContainer}>
                <Form.Item className={ResetPasswordStyles.fieldItem}>
                  {getFieldDecorator("oldPassword", {
                    rules: [
                      { required: true, message: "Old Password is required" }
                    ]
                  })(
                    <Input.Password
                      name="oldPassword"
                      placeholder="Old Password"
                      prefix={
                        <Icon
                          type="lock"
                          className={ResetPasswordStyles.homeIcon}
                        />
                      }
                    />
                  )}
                </Form.Item>
                <Form.Item className={ResetPasswordStyles.fieldItem}>
                  {getFieldDecorator("newPassword", {
                    rules: [
                      { required: true, message: "New Password is required" }
                    ]
                  })(
                    <Input.Password
                      name="newPassword"
                      placeholder="New Password"
                      prefix={
                        <Icon
                          type="lock"
                          className={ResetPasswordStyles.homeIcon}
                        />
                      }
                    />
                  )}
                </Form.Item>
                <Form.Item className={ResetPasswordStyles.fieldItem}>
                  {getFieldDecorator("verifyPassword", {
                    rules: [
                      { required: true, message: "Repeat Password is required" }
                    ]
                  })(
                    <Input.Password
                      name="verifyPassword"
                      placeholder="Repeat New Password"
                      prefix={
                        <Icon
                          type="lock"
                          className={ResetPasswordStyles.homeIcon}
                        />
                      }
                    />
                  )}
                </Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className={ResetPasswordStyles.submitButton}
                >
                  Reset Password
                </Button>
              </div>
            </Form>
          </Content>
        </div>
      </Layout>
    </React.Fragment>
  );
};

export default Form.create({ name: "ResetForm" })(ReseetAccount);
