import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Layout, Button, Spin } from "antd";
import AccountStyles from "./Account.module.css";
import PageTitle from "../PageTitle/PageTitle";
import DisclaimerFooter from "../DisclaimerFooter/DisclamerFooter";
import { getSessionStorage } from "../../sagas/utils";

const { Content } = Layout;

interface AccountProps {
  emailAddress: string;
  userType: string;
  fetchAccountInformation: Function;
  siteId: string;
  userLevel: string;
  username: string;
  accountLoading: boolean;
}

const Account: React.FC<AccountProps> = ({
  emailAddress,
  userType,
  fetchAccountInformation,
  userLevel,
  username,
  siteId,
  accountLoading
}) => {
  const email: string = emailAddress
    ? emailAddress
    : getSessionStorage("email");

  const user: string = userType ? userType : getSessionStorage("userType");

  useEffect(() => {
    fetchAccountInformation({ email, user });
  }, [email, fetchAccountInformation]);
  return (
    <React.Fragment>
      <Layout>
        <DisclaimerFooter className={AccountStyles.disclaimer} />
        <div className={AccountStyles.container}>
          <PageTitle title={"My Account"} />
          {accountLoading ? (
            <Spin size="large" />
          ) : (
            <Content className={AccountStyles.content}>
              <div>
                <h2>{userLevel}</h2>
                <table cellSpacing="30">
                  <tbody>
                    <tr>
                      <td>Email</td>
                      <td>{username}</td>
                    </tr>
                    {user === "clinical_investigator" && (
                      <tr>
                        <td>Current Site</td>
                        <td>{siteId}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <Link to="/reset-password">
                <Button>Change Password</Button>
              </Link>
            </Content>
          )}
        </div>
      </Layout>
    </React.Fragment>
  );
};

export default Account;
