import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Register from "./Register";
import { beginFetchRegisterUser } from "../../sagas/register";

function mapState(state: any) {
  return {
    emailAddress: state.login.emailAddress
  };
}

function mapDispatch(dispatch: any) {
  return {
    registerUser: bindActionCreators(beginFetchRegisterUser, dispatch)
  };
}

export default connect(
  mapState,
  mapDispatch
)(Register);
