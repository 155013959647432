export const setSessionStorage = (key: string, value: string): void => {
  return sessionStorage.setItem(key, value);
};

export const getSessionStorage = (key: string): string => {
  return sessionStorage.getItem(key) || "";
};

export const complianceColor = (value: number): string => {
  let color: string = "#02B150"; // Green
  if (value < 85 && value > 65) {
    color = "#FFBD2E"; // Orange
  } else if (value < 65) {
    color = "#DC0D03"; // Red
  }
  return color;
};
