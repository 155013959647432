import { take, put, call, select } from "redux-saga/effects";
import * as actions from "../components/Home/HomeContainer";
import { createAction } from "redux-actions";
import sibelAPI from "../apis";
import { getSessionStorage } from "./utils";

export const GET_SESSIONS_DETAILS = "GET_SESSIONS_DETAILS";
export const FINISH_GET_SESSIONS_DETAILS = "FINISH_GET_SESSIONS_DETAILS";

export const getSessionsDetails = createAction("GET_SESSIONS_DETAILS");
export const finishGetSessionsDetails = createAction(
  "FINISH_GET_SESSIONS_DETAILS"
);

export function* watchGetSessionsDetails() {
  while (true) {
    const { payload } = yield take(GET_SESSIONS_DETAILS);
    yield call(fetchGetSessionssDetails, payload);
  }
}

export function* fetchGetSessionssDetails({
  email,
  SessionID,
  userType
}: {
  email: string;
  SessionID: string;
  userType: string;
}) {
  const emailAddress = email ? email : getSessionStorage("email");
  const user = userType ? userType : getSessionStorage("userType");

  if (user !== "privileged_user") {
    try {
      const response = yield call(
        sibelAPI,
        "clinicalInvestigatorGetSessionDetails",
        {
          emailAddress,
          SessionID
        }
      );
      yield put(finishGetSessionsDetails(response.data));
    } catch (error) {
      console.log(error);
    }
  } else {
    try {
      const response = yield call(sibelAPI, "privilegedUserGetSessionDetails", {
        emailAddress,
        SessionID
      });
      yield put(finishGetSessionsDetails(response.data));
    } catch (error) {
      console.log(error);
    }
  }
}
