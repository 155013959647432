import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Help from "./Help";
import { submitHelpInformation } from "../../sagas/help";

function mapState(state: any) {
  return {
    emailAddress: state.login.emailAddress,
    isHelpSubmitting: state.help.submitting,
    helpSubmitSucceeded: state.help.succeeded
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    fetchSubmitHelpInformation: bindActionCreators(
      submitHelpInformation,
      dispatch
    )
  };
}

export default connect(
  mapState,
  mapDispatchToProps
)(Help);
