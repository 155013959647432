import React from "react";
import { History } from "history";
import { ConnectedRouter } from "connected-react-router";
import routes from "./routes";

interface RootProps {
  history: History;
}

const Root = ({ history }: RootProps) => {
  return <ConnectedRouter history={history}>{routes}</ConnectedRouter>;
};

export default Root;
