import React from "react";
import PageTitle from "../PageTitle/PageTitle";
import Avatar from "../Avatar/Avatar";
import AvatarPageTitleWrapperStyles from "./AvatarPageTitleWrapper.module.css";

interface AvatarPageTitleWrapperProps {
  className?: string;
  pageTitle: string;
  email: string;
  user: string;
}

const AvatarPageTitleWrapper: React.FC<AvatarPageTitleWrapperProps> = ({
  className,
  email,
  user,
  pageTitle
}) => {
  return (
    <div className={`${AvatarPageTitleWrapperStyles.container} ${className}`}>
      <PageTitle title={pageTitle} />
      <Avatar email={email} user={user} />
    </div>
  );
};

export default AvatarPageTitleWrapper;
