import React from "react";
import { Route, Switch, Redirect } from "react-router";
import Home from "./components/Home/HomeContainer";
import Dashboard from "./components/Dashboard/DashboardContainer";
import Help from "./components/Help/HelpContainer";
import Sessions from "./components/Sessions/SessionContainer";
import Admin from "./components/Admin/AdminContainer";
import MainLayout from "./components/Layout/Layout";
import AccountContainer from "./components/Account/AccountContainer";
import ResetPassword from "./components/ResetPassword/ResetPasswordContainer";
import RegisterContainer from "./components/Register/RegisterContainer";
import PasswordConfirmationContainer from "./components/PasswordConfirmation/PasswordConfirmation";
import { getSessionStorage } from "./sagas/utils";

function requireAuth(
  nextState: Function,
  replace: Function,
  callback: Function
) {
  const token = getSessionStorage("token");
  if (!token) {
    replace("/");
  }
  return callback();
}

const routes = (
  <React.Fragment>
    <Route exact path="/" component={Home} />
    <MainLayout />
    <Switch>
      <Route path="/dashboard" component={Dashboard} onEnter={requireAuth} />
      <Route path="/help" component={Help} onEnter={requireAuth} />
      <Route path="/sessions" component={Sessions} onEnter={requireAuth} />
      <Route path="/admin" component={Admin} onEnter={requireAuth} />
      <Route path="/reset-password" component={ResetPassword} />
      <Route path="/register" component={RegisterContainer} />
      <Route path="/confirmation" component={PasswordConfirmationContainer} />
      <Route
        path="/account"
        component={AccountContainer}
        onEnter={requireAuth}
      />
      <Route
        path="/logout"
        render={props => {
          sessionStorage.clear();
          return (
            <Redirect
              to={{
                pathname: "/"
              }}
            />
          );
        }}
      />
    </Switch>
  </React.Fragment>
);

export default routes;
