import React, { useState, useEffect } from "react";
import { Calendar, Bluetooth } from "react-feather";
import { Layout, Select, Icon, Divider } from "antd";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Label,
  ResponsiveContainer
} from "recharts";
import DashboardStyles from "./Dashboard.module.css";
import DisclaimerFooter from "../DisclaimerFooter/DisclamerFooter";
import AvatarPageTitleWrapper from "../AvatarPageTitleWrapper/AvatarPageTitleWrapper";
import PageTitle from "../PageTitle/PageTitle";
import { complianceColor } from "../../sagas/utils";

const { Option } = Select;
const { Content } = Layout;

function humanFileSize(bytes: number, si: boolean) {
  var thresh = si ? 1000 : 1024;
  if (Math.abs(bytes) < thresh) {
    return bytes + " bytes";
  }
  var units = si
    ? ["KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  var u = -1;
  do {
    bytes /= thresh;
    ++u;
  } while (Math.abs(bytes) >= thresh && u < units.length - 1);
  return Math.round(bytes * 100) / 100 + " " + units[u];
}

interface DashboardProps {
  fetchDashboardStatsClinicalInvestigator: Function;
  fetchTotalDataClinicalInvestigator: Function;
  email: string;
  activeDevices: number;
  totalData: number;
  complianceScore: number;
  userType: string;
  countries: Array<string>;
  clinicalSites: Array<string>;
  fetchComplianceScore: Function;
  graphData: Array<any>;
}

const Dashboard: React.FC<DashboardProps> = ({
  fetchDashboardStatsClinicalInvestigator,
  fetchTotalDataClinicalInvestigator,
  fetchComplianceScore,
  email,
  activeDevices,
  totalData,
  complianceScore,
  userType,
  countries,
  clinicalSites,
  graphData
}) => {
  const [countryCompliance, setCountryCompliance] = useState("None");
  const [clinicalSite, setClinicalSite] = useState("None");
  useEffect(() => {
    fetchDashboardStatsClinicalInvestigator({ email, userType });
    fetchTotalDataClinicalInvestigator({
      email,
      time_span: 7,
      userType
    });
  }, [
    email,
    fetchDashboardStatsClinicalInvestigator,
    fetchTotalDataClinicalInvestigator,
    userType
  ]);

  const handleChange = (value: any) => {
    fetchTotalDataClinicalInvestigator({
      email,
      time_span: value
    });
  };

  const selectCountry = (value: string) => {
    setCountryCompliance(value);
    fetchComplianceScore({ SiteID: "", Country: value });
    setClinicalSite("None");
  };

  const selectClinicalSite = (value: string) => {
    setClinicalSite(value);
    fetchComplianceScore({ SiteID: value, Country: countryCompliance });
  };

  let color: string = "#02B150"; // Green
  if (complianceScore === 0) {
    color = "#000000";
  } else if (complianceScore < 85 && complianceScore > 65) {
    color = "#FFBD2E"; // Orange
  } else if (complianceScore < 65) {
    color = "#DC0D03"; // Red
  }

  return (
    <React.Fragment>
      <Layout>
        <DisclaimerFooter className={DashboardStyles.disclaimer} />
        <div className={DashboardStyles.container}>
          <AvatarPageTitleWrapper
            email={email}
            pageTitle={"Dashboard"}
            user={userType}
          />
          <Content>
            <div className={DashboardStyles.topCard}>
              <div className={DashboardStyles.headerDescription}>
                <h1 className={DashboardStyles.topHeader}>{activeDevices}</h1>
                <Bluetooth size={14} />
                <span>Active Devices</span>
              </div>
              <div className={DashboardStyles.headerDescription}>
                <h1 className={DashboardStyles.topHeader}>
                  {!!totalData ? humanFileSize(totalData, true) : 0}
                </h1>
                <Icon type="rise" style={{ marginRight: "0.5em" }} />
                <span>Total Data Collected</span>
              </div>
              {userType !== "privileged_user" && (
                <div className={DashboardStyles.headerDescription}>
                  <h1
                    className={DashboardStyles.topHeader}
                    style={{ color: complianceColor(complianceScore) }}
                  >
                    {complianceScore !== null ? complianceScore : "-"} %
                  </h1>
                  <Calendar size={14} />
                  <span>Average Compliance Score</span>
                </div>
              )}
            </div>
          </Content>
          <Content className={DashboardStyles.graphComplianceContainer}>
            <div
              style={{ width: userType === "privileged_user" ? "70%" : "100%" }}
              className={DashboardStyles.graphContainer}
            >
              <div className={DashboardStyles.graphHeaderContainer}>
                <PageTitle
                  title={"DATA COLLECTED PER TIME PERIOD"}
                  className={DashboardStyles.graphHeader}
                />
                <Select
                  defaultValue="7 Days"
                  className={DashboardStyles.select}
                  onChange={handleChange}
                >
                  <Option value="7">7 Days</Option>
                  <Option value="30">1 Month</Option>
                  <Option value="365">1 Year</Option>
                </Select>
              </div>
              <div className={DashboardStyles.chartContainer}>
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    data={graphData}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 20
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" vertical={false} />
                    <XAxis dataKey="date" tickLine={false}>
                      <Label
                        value="Date"
                        offset={10}
                        position="bottom"
                        style={{ fill: "#a5a5a5" }}
                      />
                    </XAxis>
                    <YAxis tickLine={false}>
                      <Label
                        value="GB"
                        offset={0}
                        position="insideLeft"
                        style={{ fill: "#a5a5a5" }}
                      />
                    </YAxis>
                    <Tooltip
                      isAnimationActive={false}
                      cursor={false}
                      formatter={function(value, name) {
                        return [
                          humanFileSize(+value * 1000000, true),
                          "Data Collected"
                        ];
                      }}
                    />
                    <Bar dataKey="GB" fill="#0088EB" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
            {userType === "privileged_user" && (
              <Content>
                <div className={DashboardStyles.complianceContainer}>
                  <h2 className={DashboardStyles.complianceHeader}>
                    Compliance Scorecard
                  </h2>

                  <h4 className={DashboardStyles.complianceLabel}>Country</h4>
                  <Select
                    className={DashboardStyles.complianceSelect}
                    value={countryCompliance}
                    onChange={selectCountry}
                  >
                    <Option value={"None"}>{"None"}</Option>
                    {countries
                      .sort(function(a, b) {
                        if (a < b) {
                          return -1;
                        }
                        if (a > b) {
                          return 1;
                        }
                        return 0;
                      })
                      .map(val => {
                        return <Option value={val}>{val}</Option>;
                      })}
                  </Select>

                  <h4 className={DashboardStyles.complianceLabel}>
                    Clinical Site
                  </h4>
                  <Select
                    className={DashboardStyles.complianceSelect}
                    value={clinicalSite}
                    onChange={selectClinicalSite}
                  >
                    <Option value={"None"}>{"None"}</Option>
                    {clinicalSites
                      .filter(site => site.includes(countryCompliance))
                      .map(clinicalSite => {
                        return (
                          <Option value={clinicalSite}>{clinicalSite}</Option>
                        );
                      })}
                  </Select>
                  <Divider />
                  <div className={DashboardStyles.averageComplianceContainer}>
                    <h3 style={{ color: color }}>
                      <strong>
                        {complianceScore == null
                          ? "-"
                          : complianceScore.toString()}{" "}
                        %
                      </strong>
                    </h3>
                    <h4>Average Compliance Score</h4>
                  </div>
                </div>
              </Content>
            )}
          </Content>
        </div>
      </Layout>
    </React.Fragment>
  );
};

export default Dashboard;
