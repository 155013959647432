import { take, put, call } from "redux-saga/effects";
import { notification } from "antd";
import * as actions from "../components/Account/AccountContainer";
import { createAction } from "redux-actions";
import sibelAPI from "../apis";
import { getSessionStorage } from "./utils";

export const BEGIN_FETCH_ACCOUNT_INFORMATION =
  "BEGIN_FETCH_ACCOUNT_INFORMATION";
export const FINISH_FETCH_ACCOUNT_INFORMATION =
  "FINISH_FETCH_ACCOUNT_INFORMATION";

export const beginFetchAccountInformation = createAction(
  "BEGIN_FETCH_ACCOUNT_INFORMATION"
);
export const finishFetchAccountInformation = createAction(
  "FINISH_FETCH_ACCOUNT_INFORMATION"
);

export function* watchGetAccountInformation() {
  while (true) {
    const { payload } = yield take(BEGIN_FETCH_ACCOUNT_INFORMATION);
    yield call(fetchAccountInformation, payload);
  }
}

export function* fetchAccountInformation({
  email,
  user
}: {
  email: string;
  user: string;
}) {
  if (user !== "privileged_user") {
    try {
      const response = yield call(
        sibelAPI,
        "accountInformationClinicalInvestigator",
        email
      );
      yield put(finishFetchAccountInformation(response.data));
    } catch (error) {
      notification.open({
        type: "error",
        message: error.message
      });
    }
  } else {
    try {
      const response = yield call(
        sibelAPI,
        "accountInformationPrivilegedUser",
        email
      );
      yield put(finishFetchAccountInformation(response.data));
    } catch (error) {
      notification.open({
        type: "error",
        message: error.message
      });
    }
  }
}
