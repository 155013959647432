import { handleActions, combineActions } from "redux-actions";
import {
  GET_ADMIN_DASHBOARD_CLINICAL_USERS,
  FINISH_ADMIN_DASHBOARD_CLINICAL_USERS
} from "../sagas/admin";

const defaultState = {
  clinicalUsers: [],
  loading: false
};

const reducer = handleActions(
  {
    GET_ADMIN_DASHBOARD_CLINICAL_USERS: (state: any, action: any) => ({
      ...state,
      loading: true
    }),
    FINISH_ADMIN_DASHBOARD_CLINICAL_USERS: (state: any, action: any) => ({
      loading: false,
      clinicalUsers: action.payload
    })
  },
  defaultState
);

export default reducer;
