import { combineReducers } from "redux";
import { History } from "history";
import { RouterState, connectRouter } from "connected-react-router";
import LoginReducer from "./home";
import DashboardReducer from "./dashboard";
import AdminReducer from "./admin";
import SessionsReducer from "./sessions";
import SessionDetailReducer from "./sessionDetail";
import HelpReducer from "./help";
import AccountReducer from "./account";

const rootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    login: LoginReducer,
    dashboard: DashboardReducer,
    admin: AdminReducer,
    sessions: SessionsReducer,
    sessionDetail: SessionDetailReducer,
    help: HelpReducer,
    account: AccountReducer
  });

export interface State {
  router: RouterState;
}

export default rootReducer;
