import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Admin from "./Admin";
import {
  getAdminDashboardClinicalUsers,
  beginAuthorizeUser
} from "../../sagas/admin";

function mapState(state: any) {
  return {
    clinicalUsers: state.admin.clinicalUsers,
    adminLoadingUsers: state.admin.loading
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    fetchAdminDashboardClinicalUsers: bindActionCreators(
      getAdminDashboardClinicalUsers,
      dispatch
    ),
    authorizeUsers: bindActionCreators(beginAuthorizeUser, dispatch)
  };
}

export default connect(
  mapState,
  mapDispatchToProps
)(Admin);
