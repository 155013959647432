import { put } from "redux-saga/effects";
import axios from "axios";
import { getSessionStorage } from "../sagas/utils";
import { loginFailed } from "../components/Home/HomeContainer";
import { history } from "../configureStore";
//demo data
import { clinical_investigators, total_stats_7, total_stats_30, total_stats_365,
active_devices, clinical_sites, countries, total_data, sessionsz, waiting_aut, demoFunction } from "../demo/demo_info.js"

const BASE_API_URL = "https://api.sibelhealth.com/jsn/alpha";

const user_pool = waiting_aut;

export const apiRequestMap = {
  signInUser({ email, password }) {
    return {
      url: `${BASE_API_URL}/sign-in`,
      method: "POST",
      body: JSON.stringify({ email, password })
    };
  },
  accountInformationClinicalInvestigator(email) {
    return {
      url: `${BASE_API_URL}/clinical-investigators/view-account`,
      method: "POST",
      body: JSON.stringify({ email })
    };
  },
  accountInformationPrivilegedUser(email) {
    return {
      url: `${BASE_API_URL}/privileged-users/view-account`,
      method: "POST",
      body: JSON.stringify({ email })
    };
  },
  signUpClinicalInvestigator({ email, password }) {
    return {
      url: `${BASE_API_URL}/clinical-investigators/sign-up`,
      method: "POST",
      body: JSON.stringify({ email, password })
    };
  },
  clinicalInvestigatorDashboardStats(email) {
    return {
      url: `${BASE_API_URL}/clinical-investigators/dashboard-stats`,
      method: "POST",
      body: JSON.stringify({ email })
    };
  },
  clinicalInvestigatorTotalStats({ email, time_span }) {
    return {
      url: `${BASE_API_URL}/clinical-investigators/total-data`,
      method: "POST",
      body: JSON.stringify({ email, time_span })
    };
  },
  clinicalInvestigatorSessions(email) {
    return {
      url: `${BASE_API_URL}/clinical-investigators/sessions`,
      method: "POST",
      body: JSON.stringify({ email })
    };
  },
  clinicalInvestigatorGetSessionDetails({ emailAddress, SessionID }) {
    return {
      url: `${BASE_API_URL}/clinical-investigators/sessions/detail`,
      method: "POST",
      body: JSON.stringify({ emailAddress, SessionID })
    };
  },
  clinicalInvestigatorSubmitHelp({ name, email, subject, message }) {
    return {
      url: `${BASE_API_URL}/clincial-investigators/help-email`,
      method: "PUT",
      body: JSON.stringify({ name, email, subject, message })
    };
  },
  clinicalInvestigatorSignUp({ email, siteID }) {
    return {
      url: `${BASE_API_URL}/clinical-investigators/sign-up`,
      method: "POST",
      body: JSON.stringify({ email, siteID })
    };
  },
  clinicalInvestigatorResetPassword({
    email,
    oldPassword,
    newPassword,
    verifyPassword
  }) {
    return {
      url: `${BASE_API_URL}/clincial-investigators/change-password`,
      method: "PUT",
      body: JSON.stringify({ email, oldPassword, newPassword, verifyPassword })
    };
  },
  privilegedUserSessions() {
    return {
      url: `${BASE_API_URL}/privileged-users/sessions`,
      method: "GET"
    };
  },
  privilegedUserSubmitHelp({ name, email, subject, message }) {
    return {
      url: `${BASE_API_URL}/privileged-users/help-email`,
      method: "PUT",
      body: JSON.stringify({ name, email, subject, message })
    };
  },
  privilegedUserGetSessionDetails({ emailAddress, SessionID }) {
    return {
      url: `${BASE_API_URL}/privileged-users/sessions/detail`,
      method: "POST",
      body: JSON.stringify({ emailAddress, SessionID })
    };
  },
  privilegedUserTotalStats({ email, time_span }) {
    return {
      url: `${BASE_API_URL}/privileged-users/total-data`,
      method: "POST",
      body: JSON.stringify({ email, time_span })
    };
  },
  privilegedUserDashboardStats() {
    return {
      url: `${BASE_API_URL}/privileged-users/dashboard-stats`,
      method: "GET"
    };
  },
  adminDashboardGetClinicalUsers() {
    return {
      url: `${BASE_API_URL}/privileged-users/view-clinical-users`,
      method: "GET"
    };
  },
  adminDashboardAuthenticateClinicalUsers(emails) {
    return {
      url: `${BASE_API_URL}/privileged-users/view-clinical-users/authenticate`,
      method: "PUT",
      body: JSON.stringify({ email: emails })
    };
  },
  getComplianceScore({ SiteID, Country }) {
    return {
      url: `${BASE_API_URL}/privileged-users/compliance-score`,
      method: "POST",
      body: JSON.stringify({ SiteID, Country })
    };
  }
};

export default function* sibelApi(type, data) {
  const apiRequestConfiguration = apiRequestMap[type](data);
  const token = getSessionStorage("token");
  const expires = getSessionStorage("expiresIn");

  /*
  if (token && expires && expires < Date.now()) {
    sessionStorage.clear();
    history.push("/logout");
    alert("Your session has timed out. Please login again.");
  }
  */
  
  try {
    let response = null;
    let demo = true
    if (demo) {
      response = demoFunction(type,data);
      console.log(type)
      console.log(response)
      if (response.status === 200) {
        return response;
      }
      else if (response.status === 403) {
        yield put(loginFailed());
        return;
      }
      else if (response.status === 401){
        return;
      }
    }
    else {
      response = yield axios({
        method: apiRequestConfiguration.method,
        url: apiRequestConfiguration.url,
        data: apiRequestConfiguration.body,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: !!token && `Bearer ${token}`
        }
      });
    }
    return response;
  } catch (error) {
    if (
      (error.response && error.response.status === 403) ||
      error.status === 403
    ) {
      yield put(loginFailed());
    } else if (
      (error.response && error.response.status === 401) ||
      error.status === 401
    ) {
      history.push("/");
    }
    return;
  }
}
