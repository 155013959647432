import { all, call, select } from "redux-saga/effects";
import { watchLoginUser, watchSignUpUser } from "./home";
import {
  watchGetDasbboardStatsClinicalInvestigator,
  watchGetTotalDataClinicalInvestigator,
  watchGetComplianceScore
} from "./dashboard";
import { watchGetSessions } from "./sessions";
import { watchSubmitHelpInformation } from "./help";
import {
  watchGetAdminDashboardClinicalUsers,
  watchAuthorizeUsers
} from "./admin";
import { watchGetSessionsDetails } from "./sessionDetail";
import { watchGetAccountInformation } from "./account";
import { watchResetPassword } from "./resetPassword";
import { watchRegisterUser } from "./register";

export default function* rootSaga() {
  yield all([
    call(watchLoginUser),
    call(watchGetTotalDataClinicalInvestigator),
    call(watchGetDasbboardStatsClinicalInvestigator),
    call(watchGetSessions),
    call(watchSubmitHelpInformation),
    call(watchGetAdminDashboardClinicalUsers),
    call(watchGetComplianceScore),
    call(watchGetSessionsDetails),
    call(watchAuthorizeUsers),
    call(watchSignUpUser),
    call(watchGetAccountInformation),
    call(watchResetPassword),
    call(watchRegisterUser)
  ]);
}
