import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import Sessions from "./Sessions";
import { getSessions } from "../../sagas/sessions";
import { getSessionStorage } from "../../sagas/utils";

function mapState(state: any) {
  return {
    userType: state.login.userType,
    email: state.login.emailAddress || getSessionStorage("email"),
    sessions: state.sessions.data,
    sessionLoading: state.sessions.loading
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    fetchSessions: bindActionCreators(getSessions, dispatch)
  };
}

export default withRouter(connect(
  mapState,
  mapDispatchToProps
)(Sessions) as any);
