import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import SesssionDetail from "./SessionDetail";
import { getSessionsDetails } from "../../sagas/sessionDetail";

function mapState(state: any) {
  return {
    userType: state.login.userType,
    email: state.login.emailAddress,
    sessionDetailData: state.sessionDetail.data,
    sessionDetailsLoading: state.sessionDetail.loading,
    vitals: state.sessionDetail.vitals
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    fetchSessionDetails: bindActionCreators(getSessionsDetails, dispatch)
  };
}

export default connect(
  mapState,
  mapDispatchToProps
)(SesssionDetail);
