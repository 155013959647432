import React from "react";
import User from "../../assets/Admin.svg";
import { Link } from "react-router-dom";
import AvatarStyles from "./Avatar.module.css";

interface AvatarProps {
  className?: string;
  email: string;
  user: string;
}

const cleanText = (userType: string): string => {
  if (userType.includes("privileged")) {
    return "Admin";
  }
  return "Clinical Investigator";
};

const Avatar: React.FC<AvatarProps> = ({ className, email, user }) => {
  return (
    <Link to={"/account"}>
      <div className={`${AvatarStyles.container} ${className}`}>
        <img src={User} className={AvatarStyles.icon} />
        <div className={AvatarStyles.information}>
          <span className={AvatarStyles.email}>{email}</span>
          <span className={AvatarStyles.user}>{cleanText(user)}</span>
        </div>
      </div>
    </Link>
  );
};

export default Avatar;
