const month = 8
const day = 10
const year = 2019

export const clinical_investigators = [
	{
		"username": "jsn.rsv+SS19001@sibelhealth.com",
		"password": "Sibel2018",
		"siteID": "M02-TR19001"
	},
	{
		"username": "jsn.rsv+SS19002@sibelhealth.com",
		"password": "Sibel2018",
		"siteID": "M02-TR19002"
	},
	{
		"username": "jsn.rsv+AA11111@sibelhealth.com",
		"password": "Sibel2018",
		"siteID": "M02-UK11111"
	},
	{
		"username": "jsn.rsv+US12345@sibelhealth.com",
		"password": "Sibel2018",
		"siteID": "M02-US12345"
	}
];

export const clinical_sites = ["M02-UK11111","M02-TR19999","M02-US12345","M02-TR12345",
        "M02-TR19001","M02-TR19005","M02-TR19008","M02-TR19009","M02-TR11111",
        "M02-TR13337","M02-TR10033"];
//export const active_devices = data.length;
export const countries = ["UK", "TR", "US"];
export const total_data = [37451466400000]
export var active_devices = null
var actual_active_devices = 0

export var session_ids = new Array(66)

for (var i = 0; i < session_ids.length; i++) {
	var id = "07B201002".concat(makeid(7))
	while (session_ids.includes(id)) {
		id = "07B201002".concat(makeid(7))
	}
	session_ids[i] = id
}

function makeid(length) {
   var result           = '';
   var characters       = 'ABCDEF0123456789';
   var charactersLength = characters.length;
   for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
   }
   return result;
}


export var total_stats_7 = new Array(7)

var temp_day = day;
var temp_month = month;
var temp_year = year;
for (var i = 6; i >= 0; i--) {
	var date = temp_year.toString();
	if (temp_month < 10) {
		date = date.concat("-0",temp_month.toString());
	}
	else {
		date = date.concat("-",temp_month.toString());
	}
	if (temp_day < 10) {
		date = date.concat("-0",temp_day.toString());
	}
	else {
		date = date.concat("-",temp_day.toString());
	}
	total_stats_7[i] = [{"DATE": date, "DataCollected": Math.round(Math.random() * 900000 + 100000)}];
	temp_day--;
	if (temp_day === 0) {
		temp_month--;
		if (temp_month === 2 && temp_year % 4 === 0) {
			temp_day = 29;
		}
		else if (temp_month === 2) {
			temp_day = 28;
		}
		else if (temp_month === 8 || temp_month % 2 === 1) {
			temp_day = 31;
		}
		else {
			temp_day = 30;
		}
	}
	if (temp_month === 0) {
		temp_year--;
		temp_month = 12;
	}
}

export var total_stats_30 = new Array(30)

temp_day = day;
temp_month = month;
temp_year = year;
for (var i = 29; i >= 0; i--) {
	var date = temp_year.toString();
	if (temp_month < 10) {
		date = date.concat("-0",temp_month.toString());
	}
	else {
		date = date.concat("-",temp_month.toString());
	}
	if (temp_day < 10) {
		date = date.concat("-0",temp_day.toString());
	}
	else {
		date = date.concat("-",temp_day.toString());
	}
	total_stats_30[i] = [{"DATE": date, "DataCollected": Math.round(Math.random() * 900000 + 100000)}];
	temp_day--;
	if (temp_day === 0) {
		temp_month--;
		if (temp_month === 2 && temp_year % 4 === 0) {
			temp_day = 29;
		}
		else if (temp_month === 2) {
			temp_day = 28;
		}
		else if (temp_month === 8 || temp_month % 2 === 1) {
			temp_day = 31;
		}
		else {
			temp_day = 30;
		}
	}
	if (temp_month === 0) {
		temp_year--;
		temp_month = 12;
	}
}

export var total_stats_365 = new Array(12)

temp_day = day;
temp_month = month;
temp_year = year;
for (var i = 11; i >= 0; i--) {
	var date = ""
	if (temp_month === 1) {
		date = date.concat("January-",temp_year.toString());
	}
	else if (temp_month === 2) {
		date = date.concat("February-",temp_year.toString());
	}
	else if (temp_month === 3) {
		date = date.concat("March-",temp_year.toString());
	}
	else if (temp_month === 4) {
		date = date.concat("April-",temp_year.toString());
	}
	else if (temp_month === 5) {
		date = date.concat("May-",temp_year.toString());
	}
	else if (temp_month === 6) {
		date = date.concat("June-",temp_year.toString());
	}
	else if (temp_month === 7) {
		date = date.concat("July-",temp_year.toString());
	}
	else if (temp_month === 8) {
		date = date.concat("August-",temp_year.toString());
	}
	else if (temp_month === 9) {
		date = date.concat("September-",temp_year.toString());
	}
	else if (temp_month === 10) {
		date = date.concat("October-",temp_year.toString());
	}
	else if (temp_month === 11) {
		date = date.concat("November-",temp_year.toString());
	}
	else if (temp_month === 12) {
		date = date.concat("December-",temp_year.toString());
	}
	total_stats_365[i] = {"DATE": date, "DataCollected": Math.round(Math.random() * 9000000 + 1000000)};
	temp_month--;
	if (temp_month === 0) {
		temp_year--;
		temp_month = 12;
	}
}


export var sessionsz = new Array(66);

for (var i = 0; i < 66; i++) {
	let active = null;
	if (Math.random() > 0.3) {
		active = "Active";
	}
	else {
		active = "Inactive";
	}
	let compliance = Math.floor(Math.random() * 30 + 70);
	let siteID = clinical_sites[Math.floor(i/6)];
	let country = siteID.substring(4,6);
	let subjectID = "".concat(siteID.substring(6,7),siteID.substring(6,7),siteID.substring(7,11));
	let session_data_path = null;
	let downloadLink = null;
	if (active === "Active") {
		session_data_path = "906d2cae946ebaecce60be9997b12cd4/2a5e5bd36cf5db54751754dff10dcc5a";
		downloadLink = "https://anne-datahub-demo.s3.us-east-2.amazonaws.com/data/24934397eb6cda0f9b9b28fe39f4adc4/b227f0dcb8b2787ae5ab5ad3e2a717cb/D9080200EAD6485D.zip";
	}
	let sessionID = session_ids[i];
	if (active === "Active") {
		actual_active_devices++
		var data_length = Math.floor(Math.random() * 60 + 10);
		var vitals = {
			"HRAvg": Math.floor(Math.random() * 10 + 60),
			"HRMax": Math.floor(Math.random() * 5 + 90),
        	"HRMin": Math.floor(Math.random() * 5 + 50),
        	"RRAvg": Math.floor(Math.random() * 10 + 47),
        	"RRMax": Math.floor(Math.random() * 2 + 57),
        	"RRMin": Math.floor(Math.random() * 2 + 45),
        	"SP02Avg": Math.floor(Math.random() * 5 + 100),
        	"SP02Max": Math.floor(Math.random() * 5 + 110),
        	"SP02Min": Math.floor(Math.random() * 5 + 90)
		};
		sessionsz[i] = {
			"ComplianceScore": compliance,
			"Country": country,
			"RecruitmentDate": "2019-08-01T17:48:03",
			"SessionDataPath": session_data_path,
			"SessionID": sessionID,
			"SiteID": siteID,
			"SubjectID": subjectID,
			"SubjectStatus": active,
			"downloadLink": downloadLink,
			"key": sessionID,
			"detail": {
				"ANNEChestDataLength": data_length,
        		"ANNEChestDataSize": Math.floor(Math.random() * 30000 + 95000),
       			"ANNELimbDataLength": data_length,
        		"ANNELimbDataSize": Math.floor(Math.random() * 30000 + 95000),
        		"CompletionDate": "2019-08-29T18:58:09",
        		"Country": country,
        		"RecruitmentDate": "2019-08-15T18:58:09",
        		"SessionEnd": "2019-08-15T22:30:43",
        		"SessionStart": "2019-08-15T12:29:42",
        		"SiteID": siteID,
        		"SubjectID": subjectID,
        		"SubjectStatus": active,
        		"TotalTimeOfSession": data_length,
        		"Vitals": vitals,
			},
		};
	}
	else {
		var data_length = Math.floor(Math.random() * 60 + 10);
		var vitals = {
			"HRAvg": 0,
			"HRMax": 0,
        	"HRMin": 0,
        	"RRAvg": 0,
        	"RRMax": 0,
        	"RRMin": 0,
        	"SP02Avg": 0,
        	"SP02Max": 0,
        	"SP02Min": 0
		};
		sessionsz[i] = {
			"ComplianceScore": compliance,
			"Country": country,
			"RecruitmentDate": "2019-08-01T17:48:03",
			"SessionDataPath": session_data_path,
			"SessionID": sessionID,
			"SiteID": siteID,
			"SubjectID": subjectID,
			"SubjectStatus": active,
			"key": sessionID,
			"detail": {
				"ANNEChestDataLength": data_length,
        		"ANNEChestDataSize": null,
       			"ANNELimbDataLength": data_length,
        		"ANNELimbDataSize": null,
        		"CompletionDate": "2019-08-15T17:48:03",
        		"Country": country,
        		"RecruitmentDate": "2019-08-01T17:48:03",
        		"SessionEnd": "2019-08-08T04:35:36",
        		"SessionStart": "2019-08-08T00:35:20",
        		"SiteID": siteID,
        		"SubjectID": subjectID,
        		"SubjectStatus": active,
        		"TotalTimeOfSession": data_length,
        		"Vitals": vitals
			}
		};
	}
}

active_devices = [actual_active_devices]

export var waiting_aut = new Array(7)

for (var i = 0; i < waiting_aut.length; i++) {
	waiting_aut[i] = {
		"Attributes": {
			"Name": "zoneinfo",
			"Value": "m02-ss1900".concat(i.toString()).toUpperCase()
		},
		"Username": "jsn.rsv+SS1900".concat(i.toString(),"@sibelhealth.com")
	}
}
const user_pool = waiting_aut;

export function demoFunction(type,data) {
  if (type === "signInUser") {
    const username = data.email;
    const password = data.password;
    if (username === "privileged-user@gmail.com" && password === "Sibel2018") {
      return {
          "status": 200,
          "data": {"user": "privileged_user"}
        };
    }
    else {
      for (var i=0 ; i<clinical_investigators.length ; i++) {
        if (clinical_investigators[i].username === username) {
          if (clinical_investigators[i].password === password) {
            return {
              "status": 200,
              "data": {"user": "clinical_investigator"}
            };
          }
          else {
            return {
              "status": 403,
            };
          }
        }
      }
      return {
        "status": 403,
        };
    }
  }
  else if (type === "getComplianceScore") {
    var total_score = 0;
    var count = 0;
    for (var i = 0; i < sessionsz.length; i++) {
      if (sessionsz[i].SubjectStatus === "Active") {
        if (sessionsz[i].SiteID === data.SiteID) {
          total_score += sessionsz[i].ComplianceScore;
          count += 1;
        }
        else if (data.SiteID === "" && sessionsz[i].Country === data.Country) {
          total_score += sessionsz[i].ComplianceScore;
          count += 1;
        }
      }
    }
    if (count === 0) {
      return {
        "status": 200,
        "data": 0
      }; 
    }
    return {
      "status": 200,
      "data": Math.floor(total_score / count)
    }; 
  }
  else if (type === "accountInformationPrivilegedUser") {
    return {
      "status": 200,
      "data": {"SiteID":"None", "UserLevel": "Admin", "Username": "privileged-user@gmail.com"}
    };
  }
  else if (type === "privilegedUserSubmitHelp") {
    return {
      "status": 200,
    };
  }
  else if (type === "privilegedUserDashboardStats") {
    return {
      "status": 200,
      "data": {
        "ActiveDevices": active_devices,
        "ClinicalSites": clinical_sites,
        "Countries": countries,
        "TotalData": total_data
      }
    }
  }
  else if (type === "privilegedUserTotalStats") {
    if (data.time_span === "7" || data.time_span === 7) {
      return {
        "status": 200,
        "data": total_stats_7
      }
    }
    else if (data.time_span === "30" || data.time_span === 30) {
      return {
        "status": 200,
        "data": total_stats_30
      }
    }
    else if (data.time_span === "365" || data.time_span === 365) {
      return {
        "status": 200,
        "data": total_stats_365
      }
    }
  }
  else if (type === "privilegedUserSessions") {
    return {
      "status": 200,
      "data": sessionsz
    }
  }
  else if (type === "privilegedUserGetSessionDetails") {
    var sessionID = data.SessionID;
    var session = null;
    for (var i = 0; i < sessionsz.length; i++) {
      if (sessionsz[i].SessionID === sessionID) {
        session = sessionsz[i];
      }
    }
    return {
      "status": 200,
      "data": session.detail
    }
  }
  else if (type === "adminDashboardGetClinicalUsers"){
    return {
      "status": 200,
      "data": user_pool
    }
  }
  else if (type === "adminDashboardAuthenticateClinicalUsers"){
    for (var i = 0; i < data.length; i++) {
      var email = data[i];
      for (var j = 0; j < user_pool.length; j++) {
        var new_account = {
          "username": email,
          "password": "Sibel2018",
          "siteID": user_pool[j].Attributes.Value
        }
        clinical_investigators.push(new_account);
        if (user_pool[j].Username === email) {
          user_pool.splice(j,1)
          j--;
        }
      }
    }
    return {
      "status": 200,
    }
  }
  else if (type === "clinicalInvestigatorSubmitHelp") {
    return {
      "status": 200,
    };
  }
  else if (type === "accountInformationClinicalInvestigator") {
    var SiteID = null
    for (var i = 0; i < clinical_investigators.length; i++) {
      if (clinical_investigators[i].username === data) {
        SiteID = clinical_investigators[i].siteID
      }
    }
    return {
      "status": 200,
      "data": {"SiteID":SiteID, "UserLevel": "Clinical Investigator", "Username": data}
    };
  }
  else if (type === "clinicalInvestigatorSignUp") {
    var email = data.email;
    var siteID = data.siteID;
    var new_account = {
      "Attributes": {
        "Name": "zoneinfo",
        "Value": siteID
      },
      "Username": email
    }
    user_pool.push(new_account)
    return {
      "status": 201
    }
  }
  else if (type === "clinicalInvestigatorDashboardStats") {
    var email = data;
    var siteID = null
    for (var i = 0; i < clinical_investigators.length; i++) {
      if (clinical_investigators[i].username === email) {
        siteID = clinical_investigators[i].siteID
      }
    }
    var comp_score = 0
    var count = 0
    for (i = 0; i < sessionsz.length; i++) {
      if (sessionsz[i].SiteID === siteID && sessionsz[i].SubjectStatus === "Active") {
        comp_score += sessionsz[i].ComplianceScore
        count += 1
      }
    }
    console.log(comp_score)
    var avg_comp_score = 0
    if (count > 0) {
      avg_comp_score = Math.floor(comp_score / count)
    }
    return {
      "status": 200,
      "data": {
        "ActiveDevices": [count],
        "ComplianceScore": [avg_comp_score],
        "TotalData": [4514664]
      }
    }
  }
  else if (type === "clinicalInvestigatorTotalStats") {
    var email = data.email;
    var siteID = null;
    for (var i = 0; i < clinical_investigators.length; i++) {
      if (clinical_investigators[i].username === email) {
        siteID = clinical_investigators[i].siteID
      }
    }
    var check = false
    console.log(siteID)
    for (i = 0; i < clinical_sites.length; i++) {
      if (clinical_sites[i] === siteID) {
        check = true
      }
    }
    if (check === false) {
      return {
        "status": 200,
        "data": []
      }
    }
    if (data.time_span === "7" || data.time_span === 7) {
      return {
        "status": 200,
        "data": total_stats_7
      }
    }
    else if (data.time_span === "30" || data.time_span === 30) {
      return {
        "status": 200,
        "data": total_stats_30
      }
    }
    else if (data.time_span === "365" || data.time_span === 365) {
      return {
        "status": 200,
        "data": total_stats_365
      }
    }
  }
  else if (type === "clinicalInvestigatorSessions") {
    var email = data;
    var siteID = null;
    for (var i = 0; i < clinical_investigators.length; i++) {
      if (clinical_investigators[i].username === email) {
        siteID = clinical_investigators[i].siteID
      }
    }
    var temp_sessions = []
    for (i = 0; i < sessionsz.length; i++) {
      if (sessionsz[i].SiteID === siteID) {
        temp_sessions.push(sessionsz[i])
      }
    }
    return {
      "status": 200,
      "data": temp_sessions
    }
  }
  else if (type === "clinicalInvestigatorGetSessionDetails") {
    var sessionID = data.SessionID;
    var session = null;
    for (var i = 0; i < sessionsz.length; i++) {
      if (sessionsz[i].SessionID === sessionID) {
        session = sessionsz[i];
      }
    }
    return {
      "status": 200,
      "data": session.detail
    }
  }
  else if (type === "clinicalInvestigatorResetPassword") {
    var email = data.email
    var oldPassword = data.oldPassword
    var newPassword = data.newPassword
    var account = null;
    var place = 0
    for (var i = 0; i < clinical_investigators.length; i++) {
      if (email === clinical_investigators[i].username) {
        account = clinical_investigators[i]
        place = i
      }
    }
    if (account.password !== oldPassword) {
      return {
        "status": 401
      }
    }
    clinical_investigators[place] = {
      "username": account.username,
      "password": newPassword,
      "siteID": account.siteID
    }
    return {
        "status": 200
    }
  }
}





